<template>
  <v-app>
    <div v-if="isLoading" class="white-overlay">
      <v-progress-linear
        color="primary"
        indeterminate
        height="5"
      ></v-progress-linear>
      <div class="loader-wrapper"></div>
    </div>

    <default-bar />

    <default-drawer />

    <default-view />

    <default-footer />
  </v-app>
</template>

<script>
export default {
  name: "DefaultLayout",

  components: {
    DefaultBar: () =>
      import(
        /* webpackChunkName: "default-app-bar" */
        "./AppBar"
      ),
    DefaultDrawer: () =>
      import(
        /* webpackChunkName: "default-drawer" */
        "./Drawer"
      ),
    DefaultFooter: () =>
      import(
        /* webpackChunkName: "default-footer" */
        "./Footer"
      ),

    DefaultView: () =>
      import(
        /* webpackChunkName: "default-view" */
        "./View"
      )
  },
  computed: {
    isLoading() {
      return (
        this.$store.getters["app/asyncProgress"].length > 0 ||
        this.$store.getters["app/myLoading"]
      );
    }
  }
};
</script>
